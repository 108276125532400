import axios from 'axios';
import global from '../../../components/Global.vue';

let esaioBase = global.esaioServerBase;

// 查询事件分类下拉列表
export const queryEventClassifyList = params => { return axios.post(esaioBase + '/event/queryEventClassifyList', params)};
// 查询产品型号下拉列表
export const queryProductModeList = params => { return axios.post(esaioBase + '/event/queryProductModeList', params)};
// 查询事件定义国际化语言列表
export const queryEventDefI18nLanguageList = params => { return axios.post(esaioBase + '/event/queryEventDefI18nLanguageList', params)};
// 查询事件定义列表
export const queryEsaioEventDefList = params => {return axios.post(esaioBase + '/event/queryEsaioEventDefList', params)};
// 删除事件定义
export const removeEsaioEventDef = params => {return axios.post(esaioBase + '/event/removeEsaioEventDef', params)};
// 添加/修改事件定义
export const saveOrModifyEsaioEventDef = params => { return axios.post(esaioBase + '/event/saveOrModifyEsaioEventDef', params)};
// 查询事件定义信息详情
export const queryEsaioEventDefDetail = params => {return axios.post(esaioBase + '/event/queryEsaioEventDefDetail', params)};


