import {Message, MessageBox} from 'element-ui';

export default {

    success(message) {

        Message({message: message, type: 'success', showClose: true});
    },
    warning(message) {

        Message({message: message, type: 'warning', duration: 5000, showClose: true});
    },
    error(message) {
        Message({message: message, type: 'error', duration: 3000, showClose: true});
    },
    /**
     * 确认框提示.
     * @param message 消息
     * @param dangerouslyUseHTMLString 是否使用HTML片段
     * @param type 类型 success、error、info、warning
     * @param sureCallback 点击确认回调用法
     * @param cancelCallback 点击取消回调方法
     */
    confirm(message, dangerouslyUseHTMLString, type, sureCallback, cancelCallback) {

        MessageBox({
                title: "提示",
                message: message,
                dangerouslyUseHTMLString: dangerouslyUseHTMLString,
                type: type,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (action, instance)=>{

                    if (action === 'confirm') {

                        if (sureCallback) {

                            sureCallback();
                        }
                    } else if (action === 'cancel') {

                        if (cancelCallback) {

                            cancelCallback();
                        }
                    }
                }
            }
        );
    }
}