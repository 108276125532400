import axios from 'axios';
import global from '../../components/Global.vue';

let base = global.serverSite + '/doartIot/doart-modbus';

// 分页查询寄存信息列表
export const queryPageList = params => { return axios.post(base + '/registerInfo/queryPageList', params)};

// 查询寄存器信息详情
export const queryRegisterInfoDetail = params => { return axios.post(base + '/registerInfo/detail', params)};

// 删除寄存器信息
export const deleteRegisterInfo = params =>  { return axios.post(base + '/registerInfo/delete', params)};

// 寄存器信息导入
export const importRegisterInfo = params => {

    return axios({

        url: base + '/registerInfo/import',
        method: 'POST',
        ping: true,
        data: params
    });
};

// 查询点表下寄存器列表
export const queryList = params => { return axios.post(base + '/registerInfo/queryList', params)};

// 添加寄存器信息
export const addRegisterInfo = params => { return axios.post(base + '/registerInfo/add', params)};

// 编辑寄存器信息
export const editRegisterInfo = params => { return axios.post(base + '/registerInfo/edit', params)};

// 查询激活了事件的寄存器及其枚举信息
export const queryActivateEventModbusRegisterMap = params => { return axios.post(base
    + '/registerInfo/queryActivateEventModbusRegisterMap', params)};
