<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" center @close="closeEsaioEventDefDetailPage"
        :close-on-click-modal="false" width="1400px" top="0" id="esaioEventDefDetailDialog" v-if="detailDialogVisible"
        :before-close="handleDialogClose" class="event-detail">
        <el-form :model="esaioEventDefDto" :rules="formRules" ref="esaioEventDefDto" :inline="true" size="mini"
             label-position="right" label-width="80px">
            <el-row type="flex" justify="space-between" class="base-info">
                <el-form-item label="产品型号" prop="productModelId">
                    <el-select v-model="esaioEventDefDto.productModelId" clearable filterable placeholder="请选择产品型号"
                               disabled class="opt-item">
                        <el-option v-for="item in productModelSelect" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="事件等级" prop="level">
                    <el-select v-model="esaioEventDefDto.level" clearable placeholder="请选择事件等级" class="opt-item">
                        <el-option label="故障" value="1" style="color:#F56C6C"/>
                        <el-option label="告警" value="2" style="color:#E6A23C"/>
                        <el-option label="通知" value="3" style="color:#409EFF"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="事件分类" prop="classify">
                    <el-select v-model="esaioEventDefDto.classify" clearable placeholder="全部" class="opt-item">
                        <el-option v-for="item in classifySelect" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-row>
            <el-row v-show="showRegister">
                <el-col :span="12">
                    <el-form-item class="register-item" prop="registerId">
                        <el-table :data="registerTable" border size="mini" stripe @row-click="handleRegisterRowClickEvent"
                                  highlight-current-row :header-cell-style="{background:'#f5f4f4',color:'#606266'}"
                                  height="300px" style="width: 100%">
                            <el-table-column>
                                <template slot="header">
                                    <span style="font-size: larger">寄存器信息</span>
                                </template>
                                <el-table-column align="center" width="55" label="选择">
                                    <template v-slot="scope">
                                        <el-radio class="radio" v-model="esaioEventDefDto.registerId" :label="scope.row.value">&nbsp;</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="fieldEn" :show-overflow-tooltip="true" width="225px" label="字段键名"/>
                                <el-table-column prop="fieldCn" label="中文说明"/>
                                <el-table-column prop="address" label="寄存器地址" width="120px" align="center"/>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="register-item-right" prop="registerEnumId">
                        <el-table :data="registerEnumTable" border size="mini"  height="300px" style="width: 100%"
                              :header-cell-style="{background:'#f5f4f4',color:'#606266'}" stripe highlight-current-row
                              @row-click="handleRegisterEnumRowClickEvent">
                            <el-table-column>
                                <template slot="header">
                                    <span style="font-size: larger">寄存器枚举</span>
                                </template>
                                <el-table-column align="center" width="55" label="选择">
                                    <template v-slot="scope">
                                        <el-radio class="radio" v-model="esaioEventDefDto.registerEnumId" :label="scope.row.value">&nbsp;</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="enumEn" :show-overflow-tooltip="true" label="事件键名"/>
                                <el-table-column prop="enumCn" label="中文说明"/>
                                <el-table-column prop="bit" label="bit位" width="60px" align="center"/>
                                <el-table-column prop="triggerValue" label="触发值" width="75px" align="center"/>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-tabs v-model="activeName" type="card" class="tabs-info" @tab-click="handleTabClick">
                <el-tab-pane v-for="(item, index) in esaioEventDefDto.i18nList" :key="item.languageCode + index"
                    :label="getLanguageName(item.languageCode)" :name="index + ''">
                    <el-form-item label="事件名称" :prop="'i18nList.' + index + '.eventName'"
                              :rules="{required: true, validator: eventNameValidator, trigger: []}">
                        <el-input v-model="item.eventName" class="name-item"
                              :placeholder="'请输入' + getLanguageName(item.languageCode) + '事件名称'">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="告警原因" :prop="'i18nList.' + index + '.description'"
                              :rules="{validator: descriptionValidator, trigger: []}">
                        <el-input v-model="item.description" type="textarea" show-word-limit :rows="4" class="name-item"
                              :placeholder="'请输入' + getLanguageName(item.languageCode) + '告警原因'"
                              :maxlength="getLanguageName(item.languageCode) === '中文' ? 330 : 1000">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="修复建议" :prop="'i18nList.' + index + '.solution'"
                              :rules="{validator: solutionValidator, trigger: []}">
                        <el-input v-model="item.solution" type="textarea" show-word-limit :rows="4" class="name-item"
                              :placeholder="'请输入' + getLanguageName(item.languageCode) + '修复建议'"
                              :maxlength="getLanguageName(item.languageCode) === '中文' ? 330 : 1000">
                        </el-input>
                    </el-form-item>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveOrModifyEsaioEventDef" :loading="saveOrModifyLoading" size="mini">确认</el-button>
            <el-button @click="handleDialogClose" size="mini">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>

    import {queryEsaioEventDefDetail, saveOrModifyEsaioEventDef} from '@/api/esaio/event/esaioEventDefApi';
    import {queryActivateEventModbusRegisterMap} from '@/api/modbus/modbusRegister';
    import message from '@/utils/message';
    import jsonSchema from '@/api/jsonschema/json-schema';
    import Global from '@/components/Global';
    import {queryJsonSchemaByInterfaceUrl} from '@/api/jsonschema/jsonSchemaApi';
    import loading from '@/utils/cmdLoading';

    export default {

        name: 'EsaioEventDefDetail',
        props: {

            productModelList: {

                type: Array,
                default: []
            },
            classifySelect: {

                type: Array,
                default: []
            },
            languageList: {

                type: Array,
                default: []
            }
        },
        data() {

            return {

                detailDialogVisible: false,
                detailDialogTitle: '',
                saveOrModifyEsaioEventDefUrl: '/event/saveOrModifyEsaioEventDef',
                aioMiniProductModelCode: 'AioMini',
                jsonSchema: '',
                productModelSelect: [],
                esaioEventDefDto: {

                    id: null,
                    productModelId: null,
                    level: '1',
                    classify: '',
                    noticeDing: '0',
                    i18nList: [],
                    registerId: null,
                    registerEnumId: null
                },
                esaioEventDefErrorMsg: {

                    productModelId: '',
                    level: '',
                    classify: '',
                    registerId: '',
                    registerEnumId: ''
                },
                // 后端接口返回的校验不通过字段
                validateNotPassField: [],
                formRules: {

                    productModelId: [
                        {required: true, validator: this.jsonSchemaValidator, trigger: 'change'}
                    ],
                    level: [
                        {required: true, validator: this.jsonSchemaValidator, trigger: 'change'}
                    ],
                    classify: [
                        {required: true, validator: this.jsonSchemaValidator, trigger: 'change'}
                    ],
                    registerId: [
                        {required: true, validator: this.registerIdValidator, trigger: 'change'}
                    ],
                    registerEnumId: [
                        {required: true, validator: this.registerEnumIdValidator, trigger: 'change'}
                    ]
                },
                registerTable: [],
                registerEnumTable: [],
                registerInfo: {},
                showRegister: false,
                saveOrModifyLoading: false,
                activeName: '0'
            }
        },
        methods: {

            initEsaioEventDefDetailPage(id) {

                let that = this;
                that.esaioEventDefDto.id = id;
                that.detailDialogVisible = true;
                that.registerTable = [];
                that.registerEnumTable = [];
                that.esaioEventDefDto.i18nList = [];
                that.showRegister = false;
                let promiseArr = [];
                let loading = null;
                this.activeName = '0';
                if (id !== null) {

                    loading = that.openLoading('加载中...');
                    // 查询事件定义
                    let p1 = that.queryEsaioEventDefById(id);
                    promiseArr.push(p1);
                    that.detailDialogTitle = '编辑事件定义';
                    that.productModelSelect = that.productModelList;
                } else {

                    that.detailDialogTitle = '新增事件定义';
                    this.languageList.forEach(item => {

                        that.esaioEventDefDto.i18nList.push({

                            languageCode: item.languageCode,
                            eventName: '',
                            description: '',
                            solution: ''
                        });
                    })
                    that.productModelSelect = JSON.parse(JSON.stringify(that.productModelList)).filter(item => {

                        return that.aioMiniProductModelCode === item.code;
                    });
                    if (that.productModelSelect && that.productModelSelect.length > 0) {

                        that.esaioEventDefDto.productModelId = that.productModelSelect[0].id;
                        that.esaioEventDefDto.registerId = null;
                        that.esaioEventDefDto.registerEnumId = null;
                        that.initRegisterTable(that.esaioEventDefDto.productModelId);
                        that.showRegisterTable(that.esaioEventDefDto.productModelId);
                    }
                }
                let param = {

                    interfaceUrl: that.saveOrModifyEsaioEventDefUrl
                };
                let p2 = that.queryJsonSchemaByInterfaceUrl(param);
                promiseArr.push(p2);
                Promise.all(promiseArr).then(() => {

                    if (loading) {

                        loading.close();
                    }
                }).catch(err => {

                    console.error(err);
                    message.error('请求失败,请稍后再试');
                });
            },
            queryEsaioEventDefById(id) {

                let that = this;
                return new Promise((resolve, reject) => {

                    let param = {

                        id: id
                    };
                    queryEsaioEventDefDetail(param).then((res) => {

                        if (res.code === Global.response_status_success_obj) {

                            that.esaioEventDefDto = res.data;
                            that.initRegisterTable(res.data.productModelId, res.data.registerId);
                            that.showRegisterTable(res.data.productModelId);
                            resolve('');
                        } else {

                            reject(res.msg);
                        }
                    }).catch(err => {

                        console.error(err);
                        message.error('请求失败,请稍后再试');
                    });
                });
            },
            queryJsonSchemaByInterfaceUrl(param) {

                let that = this;
                return new Promise((resolve, reject) => {

                    // 查询 json schema
                    queryJsonSchemaByInterfaceUrl(param).then((res) => {

                        if (res.code === Global.response_status_success_obj) {

                            if (res.data === null) {

                                reject('程序异常,未配置json schema文件');
                                return;
                            }
                            that.jsonSchema = res.data;
                            // 将字段校验不通过的提示信息赋值给提示信息对象
                            Object.keys(res.data.properties).forEach(key => {

                                that.esaioEventDefErrorMsg[key] = res.data.properties[key].errorMessage;
                            });
                            resolve('');
                        }
                    }).catch(err => {

                        console.error(err);
                        reject('请求失败,请稍后再试');
                    });
                });
            },
            closeEsaioEventDefDetailPage() {

                this.validateNotPassField = [];
                // 重置表单
                this.$refs['esaioEventDefDto'].resetFields();
                this.esaioEventDefDto.i18nList = [];
                this.esaioEventDefDto.id = null;
                this.$emit('queryEsaioEventDefList');
            },
            jsonSchemaValidator(rule, val, callback) {

                let param = {};
                if (this.jsonSchema.required.includes(rule.field) || (!this.jsonSchema.required.includes(rule.field) && val)) {

                    param[rule.field] = val;
                }
                let check = jsonSchema.validate(this.jsonSchema, param);
                if (check === true && !this.validateNotPassField.includes(rule.field)) {

                    callback();
                } else {

                    check.rejectItemList.forEach(field => {

                        if (field === rule.field) {

                            callback(new Error(this.esaioEventDefErrorMsg[rule.field]));
                        }
                    });
                    this.validateNotPassField.forEach(field => {

                        if (field === rule.field) {

                            callback(new Error(this.esaioEventDefErrorMsg[rule.field]));
                        }
                    })
                    callback();
                }
            },
            registerIdValidator(rule, val, callback) {

                if (!this.esaioEventDefDto.productModelId) {

                    callback();
                } else {

                    let productModelInfo = this.productModelSelect.find(item => item.id === this.esaioEventDefDto.productModelId);
                    // 只有产品型号编码为 AioMini 进行寄存器id的强制校验
                    if (this.aioMiniProductModelCode === productModelInfo.code && !val) {

                        callback(new Error('请选择寄存器信息'));
                    } else {

                        callback();
                    }
                }
            },
            registerEnumIdValidator(rule, val, callback) {

                if (!this.esaioEventDefDto.productModelId) {

                    callback();
                } else {

                    let productModelInfo = this.productModelSelect.find(item => item.id === this.esaioEventDefDto.productModelId);
                    // 只有产品型号编码为 AioMini 进行寄存器枚举id的强制校验
                    if (this.aioMiniProductModelCode === productModelInfo.code && !val) {

                        callback(new Error('请选择寄存器枚举'));
                    } else {

                        callback();
                    }
                }
            },
            showRegisterTable(productModelId) {

                this.showRegister = false;
                for (let i = 0; i < this.productModelSelect.length; i++) {

                    if (this.productModelSelect[i].id === productModelId
                        && this.productModelSelect[i].code === this.aioMiniProductModelCode) {

                        this.showRegister = true;
                    }
                }
            },
            initRegisterTable(productModelId, registerId) {

                this.registerTable = [];
                this.registerEnumTable = [];
                queryActivateEventModbusRegisterMap().then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.registerInfo = res.data;
                        if (this.registerInfo[productModelId]) {

                            this.registerTable = JSON.parse(JSON.stringify(this.registerInfo[productModelId]));
                            if (registerId) {

                                for (let i = 0; i < this.registerTable.length; i++) {

                                    if (this.registerTable[i].value === registerId) {

                                        this.registerEnumTable = this.registerTable[i].children;
                                    }
                                }
                            }
                        }
                    }
                }).catch(err => {

                    console.error(err);
                    message.error('请求失败,请稍后再试');
                });
            },
            handleRegisterRowClickEvent(row) {

                this.esaioEventDefDto.registerId = row.value;
                this.registerEnumTable = row.children;
                this.esaioEventDefDto.registerEnumId = null;
                this.$refs['esaioEventDefDto'].validateField('registerId');
                this.$refs['esaioEventDefDto'].validateField('registerEnumId');
            },
            handleRegisterEnumRowClickEvent(row) {

                this.esaioEventDefDto.registerEnumId = row.value;
                this.$refs['esaioEventDefDto'].validateField('registerEnumId');
            },
            saveOrModifyEsaioEventDef() {

                this.validateNotPassField = [];
                this.$refs['esaioEventDefDto'].validate((valid) => {

                    if (valid) {

                        this.saveOrModifyLoading = true;
                        saveOrModifyEsaioEventDef(this.esaioEventDefDto).then((res) => {

                            this.saveOrModifyLoading = false;
                            if (res.code === Global.response_status_success_obj) {

                                // 关闭弹出层
                                this.handleDialogClose();
                                this.$emit('queryEsaioEventDefList');
                                if (this.esaioEventDefDto.id === null) {

                                    message.success('新增成功');
                                } else {

                                    message.success('修改成功');
                                }
                            } else {

                                if (res.msg) {

                                    message.error(res.msg);
                                }
                                if (res.data) {

                                    // 如果后端校验不通过则会将校验不通过字段返回给前端
                                    this.validateNotPassField = res.data;
                                    this.$refs['esaioEventDefDto'].validate();
                                }
                            }
                        }).catch(err => {

                            message.error('请求失败,请稍后再试');
                            console.error(err);
                            this.saveOrModifyLoading = false;
                        });
                    } else {

                        return false;
                    }
                });
            },
            getLanguageName(languageCode) {

                for (let i = 0; i < this.languageList.length; i++) {

                    if (languageCode === this.languageList[i].languageCode) {

                        return this.languageList[i].languageName;
                    }
                }
            },
            // 开启全屏遮罩
            openLoading(msg) {

                return loading.loading(msg, 30);
            },
            eventNameValidator(rule, val, callback) {

                this.descriptionSolutionValidator(rule, val, callback, 'eventName', '事件名称所有语言都不能空');
            },
            descriptionValidator(rule, val, callback) {

                this.descriptionSolutionValidator(rule, val, callback, 'description', '若填写事件描述，所有语言都不能空');
            },
            solutionValidator(rule, val, callback) {

                this.descriptionSolutionValidator(rule, val, callback, 'solution', '若填写事件解决办法，所有语言都不能空');
            },
            /**
             * 事件描述、事件处理所有语言校验.
             * @param rule 规则
             * @param val 实际值
             * @param callback 回调
             * @param name 校验的属性名
             * @param errMsg 校验错误时的提示信息
             * @param blur 是否为单个
             */
            descriptionSolutionValidator(rule, val, callback, name, errMsg, blur) {

                let data = this.esaioEventDefDto.i18nList.map(obj => obj[name]);
                // 使用every来检查所有描述,所有都为空或者所有都不为空，则返回true，否则返回false
                let allEmpty = data.every(desc => desc == null || !desc.trim());
                if (name === 'eventName' && allEmpty) {

                    // 事件名称必填
                    callback(new Error(errMsg));
                    return;
                }
                let allNotEmpty = data.every(desc => desc != null && desc.trim() !== '');
                if (allEmpty || allNotEmpty) {

                    // 所有都为空或者所有都不为空，清除校验信息
                    callback();
                    for (let i = 0; i < this.esaioEventDefDto.i18nList.length; i++) {

                        let field = 'i18nList.' + i + '.' + name;
                        if (rule.field === field) {

                            continue;
                        }
                        this.$refs.esaioEventDefDto.clearValidate(field);
                    }
                } else {

                    // 至少有一个不空，给空值加上提示信息
                    if (val == null || val === '') {

                        callback(new Error(errMsg));
                        // 跳转到未填写的语言
                        this.activeName = rule.field.substring(9, 10);
                    } else {

                        for (let i = 0; i < this.esaioEventDefDto.i18nList.length; i++) {

                            let field = 'i18nList.' + i + '.' + name;
                            let value = this.esaioEventDefDto.i18nList[i][name];
                            if (rule.field === field) {

                                continue;
                            }
                            if (value == null || value === '') {

                                let obj = this.esaioEventDefDto.i18nList[i];
                                for (let key in obj) {

                                    if (obj.hasOwnProperty(key) && key !== 'languageCode') {

                                        let field = 'i18nList.' + i + '.' + key;
                                        this.$refs.esaioEventDefDto.validateField(field);
                                    }
                                }
                                break;
                            }
                        }
                    }
                }
            },
            handleDialogClose() {

                // 重置表单中的数据
                this.$refs['esaioEventDefDto'].resetFields();
                this.detailDialogVisible = false;
            },
            handleTabClick(e) {

                this.activeName = e.index;
                for (let i = 0; i < this.esaioEventDefDto.i18nList.length; i++) {

                    if (e.index === i + '') {

                        let obj = this.esaioEventDefDto.i18nList[i];
                        for (let key in obj) {

                            if (obj.hasOwnProperty(key) && key !== 'languageCode') {

                                let field = 'i18nList.' + i + '.' + key;
                                this.$refs.esaioEventDefDto.validateField(field);
                            }
                        }
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.event-detail {

    ::v-deep .el-radio__label {

        display: none;
    }
    ::v-deep .el-form-item {

        margin-bottom: 15px;
    }
}

.register-item {

    width: calc(100% - 12px);
    ::v-deep.el-form-item__content {

        width: 100%;
        display: inline;
    }
}
.register-item-right {

    @extend .register-item;
    padding-left: 12px;
}
.name-item {

    width: 1220px;
}
.opt-item {

    width: 170px;
}
.base-info {

    border: 1px solid #E4E7ED;
    margin-bottom: 15px;
    padding: 15px 15px 0 25px;
}
.tabs-info {

    border: 1px solid #E4E7ED;
    padding: 15px 0 0 25px;
}
</style>
