import { Loading } from 'element-ui';
import message from "@/utils/message";
import "@/assets/css/cmdLoading.css";
export default {

    /**
     * 显示加载圈
     * @param text 加载圈内容
     * @param timeout 超时时间
     * @param timeoutCallback 超时回调方法
     * @returns {*} 加载圈实例
     */
    loading(text, timeout, timeoutCallback) {

        // 默认指令响应超时时间为100秒
        timeout = timeout ? timeout : 100;
        // 默认提示内容
        text = text ? text : '指令下发中，请等待指令响应...';
        let loading = Loading.service({

            target: document.getElementById('app'),
            fullscreen: true,
            lock: true,
            text: text + timeout + 's',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'cmdLoadingStyle'
        })
        // 开启倒计时定时器
        let countDown = ()=> {

            // 先判断当前加载圈是否可见，不可见直接关闭加载圈
            if (loading.visible === false) {

                clearInterval(timer);
            }
            if (timeout === 0) {

                if (timeoutCallback) {

                    timeoutCallback();
                }
                clearInterval(timer);
                message.error("指令响应超时，请重试...")
                loading.close();
            } else {

                loading.text = text + timeout + 's';
                --timeout;
            }
        }
        countDown();
        let timer = setInterval(countDown, 1000);
        return loading;
    }
}